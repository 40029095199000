import {
    FilterButton,
    FilterButtonSection,
    FilterCategoryExpandHandler,
    FilterDifficultyButton,
    FilterLabel,
    FilterSliderDuration,
    FilterSliderLength,
    FilterTagsMap,
    SearchTag
} from '../../models/search-treks-filter.model';

import { TranslateService } from '@ngx-translate/core';
import {
    TAG_CATEGORY_ID,
    TREK_AREAS,
    TREK_SEGMENTS
} from '../../../app/constants/app.constants';
import { TREK_LABELS } from '../../../app/wishtrip-common/trek-labels';
import { CommonTagCategories, CommonTags } from '../../../app/models/models';

export const idFilterButtonAll = 'ALL';
const classFilterButtonAll = 'icon-public';

export const typeFilterAreas = 'area';
export const typeFilterSegments = 'type';
export const typeFilterDifficulty = 'difficulty';
export const typeFilterLabels = 'labels';
export const typeFilterTags = 'tags';
export const typeSliderLength = 'length';
export const typeSliderDuration = 'duration';

export const sliderLength: FilterSliderLength = {
    title: 'Length',
    titleTranslationKey: 'LENGTH', // left_side: '0km',
    // right_side: '100km+',
    units: 'km',
    unitsTranslationKey: 'ABBREVIATED__KILOMETERS',
    id: 'length-slider',
    type: typeSliderLength,
    step: 1,
    min: 0,
    max: 500,
    model: 10,
    modelMax: 500
};

export const sliderDuration: FilterSliderDuration = {
    title: 'Duration',
    titleTranslationKey: 'DURATION', // left_side: '0hrs',
    // right_side: '13hrs+',
    units: 'hrs',
    unitsTranslationKey: 'ABBREVIATED__HOURS',
    id: 'dur-slider',
    type: typeSliderDuration,
    step: 1,
    min: 0,
    max: 15,
    model: 1,
    modelMax: 15
};

export const buttonsArea: FilterButton[] = [
    {
        id: TREK_AREAS.URBAN,
        type: typeFilterAreas,
        iconClass: 'WTicon-urban',
        title: 'Urban',
        titleTranslationKey: 'URBAN',
        selected: false
    },
    {
        id: TREK_AREAS.NATURE,
        type: typeFilterAreas,
        iconClass: 'WTicon-nature',
        title: 'Nature',
        titleTranslationKey: 'NATURE',
        selected: false
    }
];

export const buttonsSegmentType: FilterButton[] = [
    {
        id: TREK_SEGMENTS.WALKING,
        type: typeFilterSegments,
        iconClass: 'WTicon-walk',
        title: 'Walk',
        titleTranslationKey: 'WALK',
        selected: false
    },
    {
        id: TREK_SEGMENTS.BICYCLE,
        type: typeFilterSegments,
        iconClass: 'WTicon-bicycle',
        title: 'Bike',
        titleTranslationKey: 'BIKE',
        selected: false
    },
    {
        id: TREK_SEGMENTS.CAR,
        type: typeFilterSegments,
        iconClass: 'WTicon-car',
        title: 'Car',
        titleTranslationKey: 'CAR',
        selected: false
    }
];

export const buttonsDifficultyType: FilterDifficultyButton[] = [
    {
        type: typeFilterDifficulty,
        iconClass: 'WTicon-dif-easy',
        title: 'Easy',
        titleTranslationKey: 'EASY',
        selected: false,
        range: [0, 4]
    },
    {
        type: typeFilterDifficulty,
        iconClass: 'WTicon-dif-medium',
        title: 'Medium',
        titleTranslationKey: 'MEDIUM',
        selected: false,
        range: [4, 7]
    },
    {
        type: typeFilterDifficulty,
        iconClass: 'WTicon-dif-extreme',
        title: 'Extreme',
        titleTranslationKey: 'EXTREME',
        selected: false,
        range: [7, 10]
    }
];

export const filterLabels: FilterLabel[] = Object.keys(TREK_LABELS)
    .map((labelId) => ({
        id: labelId,
        type: typeFilterLabels,
        model: false
    }))
    .filter(
        (label) =>
            !TREK_LABELS[label.id as unknown as keyof typeof TREK_LABELS].silly
    );

export function getFiltersModels(
    TAGS: CommonTags,
    TAGS_CATEGORY: CommonTagCategories,
    selectedLanguage: string,
    Translate: TranslateService
): FilterTagsMap {
    const filterTags: FilterTagsMap = {};

    Object.keys(TAGS_CATEGORY).forEach((key) => {
        if (
            key === TAG_CATEGORY_ID.season ||
            key === TAG_CATEGORY_ID.generalInfo ||
            key === TAG_CATEGORY_ID.friendliness
        ) {
            //Don't Handle General Information, Recommended Seasons and
            // Friendliness
            return;
        }

        const category = TAGS_CATEGORY[key].name[selectedLanguage];
        const categoryLimit = 3;
        const tags: SearchTag[] = _getSearchTags(
            TAGS_CATEGORY[key].tags,
            TAGS,
            typeFilterTags,
            selectedLanguage
        );

        filterTags[category] = {
            id: TAGS_CATEGORY[key].id,
            model: false,
            expand: _getCategoryExpandHandler(
                tags.length,
                categoryLimit,
                Translate
            ),
            tags
        };
    });

    return filterTags;
}

export function getFilterTagById(
    categoryId: string,
    TAGS: CommonTags,
    TAGS_CATEGORY: CommonTagCategories,
    selectedLanguage: string,
    sectionTitle?: string
): FilterButtonSection {
    const category = TAGS_CATEGORY[categoryId];
    const tags = category.tags.sort();
    return {
        title: sectionTitle || category.name[selectedLanguage],
        options: tags.map((tag) => ({
            id: tag,
            type: typeFilterTags,
            iconClass: TAGS[tag].class,
            selected: false,
            title: TAGS[tag].name[selectedLanguage]
        }))
    };
}

/**
 *
 *
 * Get expand handler for TagCategory
 *
 *
 *
 * @param categoryTagsSize amount of tags in category
 * @param categoryLimit the number of visible tags when category isn't
 *     expanded
 * @param Translate
 * @return
 * @private
 */
function _getCategoryExpandHandler(
    categoryTagsSize: number,
    categoryLimit: number,
    Translate: TranslateService
): FilterCategoryExpandHandler {
    return {
        limit: categoryLimit,
        show: false,
        isExpandable(index) {
            return index < this.limit ? true : this.show;
        },
        toggleExpand() {
            this.show = !this.show;
            const amount = categoryTagsSize - this.limit;
            this.toggle_title = this.show
                ? Translate.instant('BUTTON_LESS--AMOUNT', { amount })
                : Translate.instant('BUTTON_MORE--AMOUNT', { amount });
        },
        toggle_title: Translate.instant('BUTTON_MORE--AMOUNT', {
            amount: categoryTagsSize - categoryLimit
        })
    };
}

/**
 *
 *
 * Get search tags
 *
 *
 *
 * @param tagIds
 * @param TAGS
 * @param type
 * @param selectedLanguage
 * @return
 * @private
 */
function _getSearchTags(
    tagIds: string[],
    TAGS: CommonTags,
    type: string,
    selectedLanguage: string
): SearchTag[] {
    return tagIds.map((tagId) => ({
        ...TAGS[tagId],
        id: tagId.toString(),
        model: false,
        localizedName: TAGS[tagId].name[selectedLanguage],
        type
    }));
}
